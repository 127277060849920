import React, { useState } from 'react'

import SearchURLPreloader from 'components/shared/SearchURLPreloader'
import SEO from 'components/shared/SEO'
import useAsyncEffect from 'utils/hooks/useAsyncEffect'
import Layout from 'layouts/Page'
import { DatabaseService } from 'services'
import ProfilePage from 'components/ProfilePage'

function ProfileBase(props) {
  const { user } = props

  const { id } = user
  const [loadingReviews, setLoadingReviews] = useState(true)
  const [loadingItems, setLoadingItems] = useState(true)

  const [reviews, setReviews] = useState([])

  useAsyncEffect(async () => {
    const qSnap = await DatabaseService.getReviewsByLoaner(id)
    const data = qSnap.docs.map(docSnap => {
      return { id: docSnap.id, ...docSnap.data() }
    })
    setReviews(data)
    setLoadingReviews(false)
  }, [id])

  const [items, setItems] = useState([])

  useAsyncEffect(async () => {
    const qSnap = await DatabaseService.getFleetByOwner(id)
    const data = qSnap.docs.map(docSnap => {
      return { id: docSnap.id, ...docSnap.data() }
    })
    setItems(data)
    setLoadingItems(false)
  }, [id])

  return (
    <ProfilePage
      user={user}
      reviews={reviews}
      items={items}
      loadingItems={loadingItems}
      loadingReviews={loadingReviews}
    />
  )
}

export default ({ location }) => (
  <SearchURLPreloader load={{ u: false }} location={location}>
    {({ searchData: { u: data }, ...props }) => (
      <Layout location={location}>
        <SEO
          title={`${data.firstName}'s Profile · Borrow items from ${
            data.firstName
          }`}
          image={data.picture}
          pathname={location.pathname}
        />
        <ProfileBase {...props} user={data} />
      </Layout>
    )}
  </SearchURLPreloader>
)
