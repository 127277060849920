import React from 'react'

import styles from './ProfilePage.module.scss'
import ProfileItems from './ProfileItems'
import ProfileReviews from './ProfileReviews'
import UserCard from 'components/Users/UserCard'

export default ({ user, items, reviews, loadingReviews, loadingItems }) => (
  <div className={styles.container}>
    <div className={styles.dashboardContainer}>
      <div className={styles.sideBar}>
        <UserCard user={user} showMessage />
      </div>
      <div className={styles.contentWrapper}>
        <ProfileItems items={items} loading={loadingItems} />
        <ProfileReviews reviews={reviews} loading={loadingReviews} />
      </div>
    </div>
  </div>
)
