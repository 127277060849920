import React from 'react'

import styles from './ProfileReviews.module.scss'
import ReviewList from 'components/Reviews/ReviewList'

export default ({ reviews, loading }) => (
  <div className={styles.reviewContainer}>
    <ReviewList
      reviews={reviews}
      loading={loading}
      className={styles.reviewContainer}
    />
  </div>
)
