import React from 'react'

import styles from './ProfileItems.module.scss'
import SmoothSlider from 'components/shared/Slider/SmoothSlider'
import ItemDisplay from 'components/Displays/ItemDisplay'

export default ({ items }) => {
  if (!items) {
    return <div />
  }
  return (
    <>
      <h2 className={styles.header}>Featured Items</h2>
      <SmoothSlider>
        {items.map(item => (
          <div key={item.id} className="slider-item">
            <ItemDisplay product={item} hideProfile={true} />
          </div>
        ))}
      </SmoothSlider>
    </>
  )
}
